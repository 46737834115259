import { Injectable } from "@angular/core";
import { RoomUpgradeProduct } from "@find-hotels-app/shared/models/enhance-stay-product.model";
import { SelectedRoomRateOffer } from "@find-hotels-app/shared/models/room-rate-offer.model";
import { AvailOffer } from "@ihg/availability";
import { ILineItem } from "@ihg/shopping";

import { ISearchCriteria } from "@shared/models/search-criteria";
import {
  BackPage,
  NonRoomInventoryListModel,
  RoomUpsellProduct,
  SelectedCurrencyInfo,
  ShoppingCartItem,
  ShoppingCartItemType,
} from "@shared/models/shopping-cart.model";
import { CurrencyInfo } from "../../api/currency-conversion/models/currency-info.model";
import { ShoppingCartItemBuilderServiceInterface } from "./shopping-cart-item-builder.service.interface";

interface CrossSellProduct {
  inventoryTypeCode: string;
  inventoryTypeName: string;
  productCode: string;
  quantity: number;
  rateCode: string;
  rateName: string;
}

export interface MainCrossSellOffers {
  mainOffer: AvailOffer;
  crossSellOffers: CrossSellProduct[];
}

@Injectable({
  providedIn: "root",
})
export class ShoppingCartItemBuilderService implements ShoppingCartItemBuilderServiceInterface {
  buildLineItems(mainCrossSellOffers: MainCrossSellOffers[]): ILineItem[] {
    const offers = mainCrossSellOffers.map((mainCrossSellOffer, index) => {
      const parentId = index + 1;
      const mainOffer = mainCrossSellOffer.mainOffer;
      const main: ILineItem = this.buildMainProduct(parentId, mainOffer);
      const crossSellProducts: ILineItem[] = mainCrossSellOffer.crossSellOffers.map((crossSellProduct, index) => {
        return this.buildCrossSellProduct(parentId, index + 1, mainOffer, crossSellProduct);
      });
      return [main, ...crossSellProducts];
    });
    return offers.flat();
  }

  buildCrossSellProduct(
    parentLineItemId: number,
    lineItemId: number,
    availOffer: AvailOffer,
    crossSellProduct: CrossSellProduct
  ): ILineItem {
    const guestCounts = availOffer.room.guestCounts;

    return {
      lineItemId: lineItemId,
      parentLineItemId: parentLineItemId,
      startDate: availOffer.checkIn,
      endDate: availOffer.checkOut,
      hotelMnemonic: availOffer.hotel.mnemonic,
      rates: {
        ratePlanCodes: [
          {
            internal: crossSellProduct.rateCode,
            name: crossSellProduct.rateName,
          },
        ],
      },
      products: [
        {
          inventoryTypeCode: crossSellProduct.inventoryTypeCode,
          inventoryTypeName: crossSellProduct.inventoryTypeName,
          productCode: crossSellProduct.productCode,
          guestCounts: guestCounts,
          quantity: crossSellProduct.quantity,
          isMainProduct: true,
        },
      ],
    };
  }

  buildMainProduct(lineItemId: number, availOffer: AvailOffer): ILineItem {
    const rate = availOffer.rate;
    const room = availOffer.room;
    const isGroup = rate.isGroup;
    const isFree = rate.isFree;
    const isPointsOnly = rate.rewardNights.isPointsOnly;
    const isPointsAndCash = rate.rewardNights.isPointsAndCash;
    const currency = isPointsOnly || isPointsAndCash ? "USD" : "";
    const travelAgencyNumber = availOffer.iataNumber.length > 0 ? availOffer.iataNumber : "";
    let totalPoints = 0;
    let totalCash = 0;
    const offerIds = isFree && availOffer.offerCode.length > 0 ? [availOffer.offerCode] : [];
    const corporateAccounts = availOffer.corporateNumber.length > 0 ? [availOffer.corporateNumber] : [];
    if (isPointsOnly || isPointsAndCash) {
      totalPoints = isPointsOnly ? rate.rewardNights.pointsOnly.totalPoints : rate.rewardNights.pointsCash.totalPoints;
      totalCash = isPointsOnly ? 0 : rate.rewardNights.pointsCash.totalCash;
    }
    const guestCounts = room.guestCounts;
    const additionalProducts = availOffer.rate.isPreDefinedPackage
      ? availOffer.includedProducts.map((product) => ({
          inventoryTypeCode: product.inventoryTypeCode,
          inventoryTypeName: product.inventoryTypeName,
          productCode: product.productCode,
          guestCounts: guestCounts,
          quantity: product.quantity,
          isMainProduct: false,
        }))
      : [];
    const rateValue = isGroup
      ? { groupCode: rate.code, groupName: rate.name }
      : {
          rates: {
            ratePlanCodes: [
              {
                internal: rate.code,
                name: rate.name,
              },
            ],
          },
        };

    return {
      lineItemId: lineItemId,
      startDate: availOffer.checkIn,
      endDate: availOffer.checkOut,
      hotelMnemonic: availOffer.hotel.mnemonic,
      ...rateValue,
      products: [
        {
          inventoryTypeCode: room.inventoryTypeCode,
          inventoryTypeName: room.inventoryTypeName,
          productCode: room.productCode,
          guestCounts: guestCounts,
          quantity: room.quantity,
          isMainProduct: room.isMainProduct,
        },
        ...additionalProducts,
      ],
      options: {
        loyalty: {
          loyaltyId: "",
        },
        offerIds: offerIds,
        totalPoints: totalPoints,
        totalCash: totalCash,
        currency: currency,
        depositId: isPointsAndCash ? rate.rewardNights.pointsCash.depositId : undefined,
      },
      corporateAccounts: corporateAccounts,
      travelAgencyNumber: travelAgencyNumber,
    };
  }

  buildHotelReservationItem(
    selectedRoomRateOffer: SelectedRoomRateOffer,
    searchCriteria: ISearchCriteria,
    currentCurrency: string,
    availableCurrencies: CurrencyInfo[],
    nonRoomInventoryList: NonRoomInventoryListModel[],
    upsellProduct: RoomUpgradeProduct = null,
    backPage: BackPage = "crr",
    eysChanges = false
  ): ShoppingCartItem {
    const isRateFree = this.shouldSetFreeNight(searchCriteria, selectedRoomRateOffer);
    const isGroupRate = this.shouldSetGroupCode(searchCriteria, selectedRoomRateOffer);
    let roomUpsellProduct: RoomUpsellProduct = null;
    if (upsellProduct !== null) {
      roomUpsellProduct = {
        name: upsellProduct.productName,
        pricingFrequency: upsellProduct.pricingFrequency,
        productCode: upsellProduct.productCode,
        upsellReason: upsellProduct.upsellReason,
        priceDifference: upsellProduct.cashBeforeTax,
        taxDifference: upsellProduct.tax,
      };
    }
    return <ShoppingCartItem>{
      itemType: ShoppingCartItemType.HOTEL_RESERVATION,
      creationTime: this.getCreationTime(),
      checkinDate: this.getShoppingCartDateString(searchCriteria.checkInDate),
      checkoutDate: this.getShoppingCartDateString(searchCriteria.checkOutDate),
      hotelId: searchCriteria.hotelMnemonic,
      corpId: searchCriteria.corporateId || null,
      freeNightId: isRateFree && searchCriteria.freeNightType ? searchCriteria.freeNightType : null,
      groupCode: isGroupRate ? searchCriteria.groupCode : null,
      iataNumber: searchCriteria.iataNumber || null,
      numAdults: searchCriteria.rooms[0].numberOfAdults,
      numChildren: searchCriteria.rooms[0].numberOfChildren,
      childrenAges: searchCriteria.rooms[0].childrenAges,
      numRooms: searchCriteria.rooms[0].numberOfRooms,
      pointsAndCashId: selectedRoomRateOffer.id,
      rateCode: selectedRoomRateOffer.rateCode,
      roomCode: selectedRoomRateOffer.roomCode,
      reservationId: searchCriteria.confirmationNumber ?? null, // placeholder for stay mgmt (change)
      nonRoomInventoryList: nonRoomInventoryList ?? [],
      roomUpsellProduct: roomUpsellProduct,
      selectedCurrency: this.getSelectedCurrencyInfo(currentCurrency, availableCurrencies),
      backPage: backPage,
      eysChanges,
      roomProductCode: searchCriteria.roomProductCode ?? "",
      ibrMealPlanCodesAndQuantities: searchCriteria.ibrMealPlanCodesAndQuantities ?? [],
    };
  }

  shouldSetGroupCode(searchCriteria: ISearchCriteria, selectedRoomRateOffer: SelectedRoomRateOffer): boolean {
    return (
      searchCriteria.groupCode &&
      selectedRoomRateOffer.rateCode.toUpperCase() === searchCriteria.groupCode.toUpperCase()
    );
  }

  shouldSetFreeNight(searchCriteria: ISearchCriteria, selectedRoomRateOffer: SelectedRoomRateOffer): boolean {
    return (
      searchCriteria.freeNightRateCode &&
      searchCriteria.freeNightRateCode.toUpperCase() === selectedRoomRateOffer.rateCode.toUpperCase()
    );
  }

  /**
   * Returns date in correct format for shopping cart: m/d/YYYY, eg 6/7/2021 for ISO date 2021-01-13
   * @param date
   */
  getShoppingCartDateString(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }

  getCreationTime(): number {
    return new Date().getTime();
  }

  getSelectedCurrencyInfo(currentCurrency: string, availableCurrencies: CurrencyInfo[] = []): SelectedCurrencyInfo {
    const currency = availableCurrencies && availableCurrencies.find((ac) => ac.code === currentCurrency);
    if (currency) {
      return <SelectedCurrencyInfo>{
        code: currency.code,
        name: currency.name,
        symbol: currency.symbol,
      };
    } else {
      return <SelectedCurrencyInfo>{};
    }
  }
}
