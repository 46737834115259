{
    "name": "find-hotels-ng",
    "version": "v14.108.2",
    "license": "MIT",
    "scripts": {
        "prepare": "husky",
        "ng": "ng",
        "server": "node ./node_modules/app-resource-router/lambda",
        "server:unsecure": "cross-env UNSECURE_SERVER=true node ./node_modules/app-resource-router/lambda",
        "build": "npm run find-deadcode && npm run lint && node ./scripts/pre-build && npm run build-cli:prod && node ./scripts/post-build",
        "build:dev": "node ./scripts/pre-build && npm run build-cli:dev",
        "build-cli:prod": "ng build",
        "build-cli:dev": "ng build --configuration=development --watch --source-map",
        "deploy:dev": "npm-run-all -p build:dev server",
        "find-deadcode": "ts-prune --error",
        "knip": "knip",
        "lint:fix": "ng lint --max-warnings 31 --fix=true --quiet",
        "lint:warn": "ng lint --max-warnings 31 --fix=false",
        "lint": "ng lint --max-warnings 31 --fix=false --quiet",
        "test": "cross-env NODE_OPTIONS=--max-old-space-size=2500 ng test --no-watch --no-progress",
        "postreport": "jrm ./results/combined-report.xml \"./results/results-*.xml\"",
        "cy:open": "cypress open",
        "cypress:record-electron": "npx cypress run --headless --record --parallel --env ci-build-id=$BUILD_NUMBER --key 38b7719a-8a1d-4943-a3b5-8d8e54fa8295 --env branch=$BRANCH_NAME",
        "cypress:record-chrome": "npx cypress run --browser chrome --headless --record --parallel --env ci-build-id=$BUILD_NUMBER --key 38b7719a-8a1d-4943-a3b5-8d8e54fa8295 --env branch=$BRANCH_NAME",
        "cypress:record-firefox": "npx cypress run --browser firefox --headless --record --parallel --env ci-build-id=$BUILD_NUMBER --key 38b7719a-8a1d-4943-a3b5-8d8e54fa8295 --env branch=$BRANCH_NAME",
        "cypress:dashboard-electron": "npm run build && npm-run-all -p cypress:record-electron || npm run postreport",
        "cypress:dashboard-chrome": "npm run build && npm-run-all -p cypress:record-chrome || npm run postreport",
        "cypress:dashboard-firefox": "npm run build && npm-run-all -p cypress:record-firefox || npm run postreport"
    },
    "private": true,
    "dependencies": {
        "@angular/animations": "~18.2.9",
        "@angular/common": "~18.2.9",
        "@angular/compiler": "~18.2.9",
        "@angular/core": "~18.2.9",
        "@angular/forms": "~18.2.9",
        "@angular/platform-browser": "~18.2.9",
        "@angular/platform-browser-dynamic": "~18.2.9",
        "@angular/router": "~18.2.9",
        "@angular/service-worker": "~18.2.9",
        "@google/markerclusterer": "1.0.3",
        "@ihg/army-services": "1.0.2",
        "@ihg/availability": "9.0.3",
        "@ihg/common": "0.0.51",
        "@ihg/custom-pipes": "~1.0.3",
        "@ihg/finance": "0.0.8",
        "@ihg/gql-proxy": "0.0.68",
        "@ihg/hotel-details": "2.0.47",
        "@ihg/logging": "0.0.4",
        "@ihg/members": "3.0.28",
        "@ihg/reservation": "6.0.0",
        "@ihg/shopping": "0.0.5",
        "@ihg/ui-components": "5.3.96",
        "@ngrx/component-store": "~18.1.0",
        "@ngrx/effects": "~18.1.0",
        "@ngrx/operators": "^18.0.0",
        "@ngrx/router-store": "~18.1.0",
        "@ngrx/store": "~18.1.0",
        "@ngrx/store-devtools": "~18.1.0",
        "bootstrap": "4.6.2",
        "date-fns": "3.6.0",
        "ihg-common-booking-component": "2.5.46",
        "ihg-ng-dao-service": "0.1.39",
        "ihg-ng-prime": "1.0.5",
        "lodash-es": "4.17.21",
        "ng-inline-svg": "~13.1.2",
        "ngx-cookie-service": "18.0.0",
        "ngx-device-detector": "8.0.0",
        "ngx-infinite-scroll": "15.0.0",
        "ngx-webstorage": "18.0.0",
        "primeicons": "1.0.0",
        "rxjs": "~7.8.1",
        "swiper": "7.4.1",
        "tslib": "2.6.3",
        "zone.js": "~0.14.2"
    },
    "devDependencies": {
        "@angular-devkit/build-angular": "~18.2.10",
        "@angular-devkit/core": "~18.2.10",
        "@angular-devkit/schematics": "~18.2.10",
        "@angular-eslint/builder": "18.4.0",
        "@angular-eslint/eslint-plugin": "18.4.0",
        "@angular-eslint/eslint-plugin-template": "18.4.0",
        "@angular-eslint/schematics": "18.4.0",
        "@angular-eslint/template-parser": "18.4.0",
        "@angular/cli": "~18.2.10",
        "@angular/compiler-cli": "~18.2.9",
        "@cypress-audit/lighthouse": "1.4.2",
        "@ianvs/prettier-plugin-sort-imports": "4.2.1",
        "@ngrx/schematics": "~18.1.0",
        "@testing-library/angular": "15.1.0",
        "@testing-library/user-event": "~14.4.3",
        "@types/googlemaps": "^3.37.0",
        "@types/jasmine": "~3.8.0",
        "@types/lodash-es": "^4.17.4",
        "@types/node": "^12.20.55",
        "@typescript-eslint/eslint-plugin": "^7.2.0",
        "@typescript-eslint/parser": "^7.2.0",
        "@typescript-eslint/utils": "^7.2.0",
        "adm-zip": "0.5.2",
        "app-resource-router": "~1.5.76",
        "cross-env": "7.0.3",
        "cypress": "13.16.1",
        "cypress-junit-reporter": "1.3.1",
        "cypress-real-events": "1.13.0",
        "cypress-wait-until": "1.7.2",
        "cypress-xpath": "2.0.1",
        "eslint": "^8.57.0",
        "junit-report-merger": "^6.0.3",
        "eslint-config-prettier": "9.1.0",
        "eslint-plugin-prettier": "5.1.3",
        "eslint-plugin-sonarjs": "0.18.0",
        "font-awesome": "4.7.0",
        "fs-extra": "8.1.0",
        "husky": "9.0.11",
        "jasmine-core": "4.0.1",
        "jasmine-marbles": "~0.9.2",
        "jasmine-spec-reporter": "7.0.0",
        "karma": "6.4.2",
        "karma-chrome-launcher": "3.2.0",
        "karma-coverage": "~2.0.3",
        "karma-jasmine": "4.0.2",
        "karma-jasmine-html-reporter": "~1.7.0",
        "karma-junit-reporter": "~2.0.1",
        "karma-spec-reporter": "0.0.32",
        "knip": "2.43.0",
        "ng-mocks": "~14.12.1",
        "npm-run-all": "4.1.5",
        "prettier": "3.3.1",
        "prettier-eslint": "16.3.0",
        "ts-node": "~8.3.0",
        "ts-prune": "0.10.3",
        "typescript": "~5.5.4"
    }
}