import { Injectable } from "@angular/core";
import { AvailOffer } from "@ihg/availability/lib/models";
import { ShoppingCartExtras, ShoppingCartFacadeService } from "@ihg/shopping";
import { SessionStorageService } from "ngx-webstorage";

import { RoomUpgradeProduct } from "@shared/models/enhance-stay-product.model";
import { SelectedRoomRateOffer } from "@shared/models/room-rate-offer.model";
import { ISearchCriteria } from "@shared/models/search-criteria";
import { CurrencyInfo } from "@shared/services/api/currency-conversion/models/currency-info.model";
import {
  MainCrossSellOffers,
  ShoppingCartItemBuilderService,
} from "@shared/services/session-state/builders/shopping-cart-item-builder.service";
import { BackPage, NonRoomInventoryListModel, ShoppingCartItem } from "../../models/shopping-cart.model";
import { ShoppingCartServiceInterface } from "./shopping-cart.service.interface";

const SHOPPING_CART_KEY = "shoppingCart";

@Injectable({
  providedIn: "root",
})
export class ShoppingCartService implements ShoppingCartServiceInterface {
  constructor(
    private sessionStorageService: SessionStorageService,
    private shoppingCartItemBuilderService: ShoppingCartItemBuilderService,
    private shoppingCartFacadeService: ShoppingCartFacadeService
  ) {}

  saveLineItems(mainCrossSellOffers: MainCrossSellOffers[], extras: ShoppingCartExtras): void {
    const lineItems = this.shoppingCartItemBuilderService.buildLineItems(mainCrossSellOffers);
    this.shoppingCartFacadeService.saveLineItems(lineItems);
    this.shoppingCartFacadeService.setExtras(extras);
  }

  add(
    availOffer: AvailOffer,
    selectedRoomRateOffer: SelectedRoomRateOffer,
    searchCriteria: ISearchCriteria,
    currentCurrency: string,
    availableCurrencies: CurrencyInfo[],
    nonRoomInventoryList: NonRoomInventoryListModel[],
    upsellProduct: RoomUpgradeProduct = null,
    backPage: BackPage = "crr",
    eysChanges = false
  ): void {
    this.addToCart(
      this.shoppingCartItemBuilderService.buildHotelReservationItem(
        selectedRoomRateOffer,
        searchCriteria,
        currentCurrency,
        availableCurrencies,
        nonRoomInventoryList,
        upsellProduct,
        backPage,
        eysChanges
      )
    );

    const mainCrossSellOffers: MainCrossSellOffers = {
      mainOffer: availOffer,
      crossSellOffers: nonRoomInventoryList.map((item) => ({
        inventoryTypeCode: item.productCode,
        inventoryTypeName: item.name,
        productCode: item.productTypeCode,
        quantity: item.quantity,
        rateCode: item.ratePlanCode,
        rateName: item.ratePlanName,
      })),
    };

    const upsellProductValue = upsellProduct
      ? {
          name: upsellProduct.productName,
          pricingFrequency: upsellProduct.pricingFrequency,
          productCode: upsellProduct.productCode,
          upsellReason: upsellProduct.upsellReason,
          priceDifference: upsellProduct.cashBeforeTax,
          taxDifference: upsellProduct.tax,
        }
      : undefined;

    this.saveLineItems([mainCrossSellOffers], {
      currency: currentCurrency,
      backPage: backPage,
      upsellProduct: upsellProductValue,
    });
  }

  addToCart(item: ShoppingCartItem): void {
    this.sessionStorageService.store(SHOPPING_CART_KEY, {
      items: [item],
    });
  }
}
